<!--
 * @FilePath: \zhishe-pc\src\views\other\components\About.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-25 14:21:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-17 10:51:38
-->
<!--关于我们组件-->
<template>
  <div>
    <div class="top" v-if="data">
      <p>{{ data.cntitle }}</p>
    </div>
    <div class="content">
      <div v-html="data.content"></div>
    </div>
    <div v-for="(item, index) in datas" :key="index">
      <div class="border"></div>
      <div class="content">
        <div v-html="item.cntitle" class="title"></div>
        <div v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOther } from "../../../Api";
export default {
  name: "About",
  props:["type"],
  data() {
    return {
      data: {},
      datas: [],
    };
  },
  created() {
    let cate = 1;
    if(this.type == 1){
      cate = 1;
    } else if(this.type == 2){
      cate = 1143; 
    } else if(this.type == 3){
      cate = 2; 
    }
    getOther(cate).then((res) => {
      this.data = res.data[0];
      this.datas = res.data.splice(1, 100);
    });
  },
};
</script>

<style scoped>
.top {
  align-items: center;
  padding: 0.2rem 0 0.2rem 0.3rem;
  border-bottom: 1px solid #d9d9d9;
}
.top p {
  height: 0.25rem;
  font-size: 0.18rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #040404;
  opacity: 1;
}

.content {
  padding: 0.3rem;
}

.border {
  border-top: 1px dashed #d9d9d9;
  margin:0 0.3rem;
}

.title {
  /* height: 0.22rem; */
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  opacity: 1;
  margin-bottom: 0.2rem;
}
</style>