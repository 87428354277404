<!--
 * @FilePath: \zsw_new_edition\src\views\other\index.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-12-06 16:21:19
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-02-28 10:57:45
-->
<template>
  <div class="otherBox">
    <div class="leftBox">
      <ul>
        <li :class="{ active: type == 1 }" @click="change(1)">关于知设</li>
        <li :class="{ active: type == 2 }" @click="change(2)">知设协议</li>
        <li :class="{ active: type == 3 }" @click="change(3)">常见问题</li>
        <li :class="{ active: type == 4 }" @click="change(4)">意见反馈</li>
        <li :class="{ active: type == 5 }" @click="change(5)">平台集锦</li>
      </ul>
    </div>
    <div class="rightBox">
      <About v-if="type < 4 && type != 2" :key="timer" :type="type"></About>
      <Protocol v-if="type == 2" :key="timer" :type="type"></Protocol>
      <Feedback v-if="type == 4"></Feedback>
      <Platform v-if="type == 5"></Platform>
    </div>
  </div>
</template>

<script>
import About from "./components/About";
import Feedback from "./components/Feedback";
import Platform from "./components/Platform.vue";
import Protocol from "./components/Protocol.vue";
export default {
  name: "OtherIndex",
  components: {
    About,
    Feedback,
    Platform,
    Protocol,
  },
  data() {
    return {
      type: 1,
      data: {},
      timer: "",
    };
  },
  created() {
    this.type = this.$route.query.type;
  },
  methods: {
    change(type) {
      this.timer = new Date().getTime();
      this.$router.push({ query: { type: type } });
      this.type = type;
    }
  },
  // watch: {
  //   $route() {
  //     if (this.$route.query.type) {
  //       this.change(this.$route.query.type);
  //     }
  //   },
  // },
};
</script>

<style scoped>
.otherBox {
  min-height: 70vh;
  display: flex;
  margin: 0.2rem 2.8rem;
}

.leftBox {
  position: fixed;
  width: 2.4rem;
  height: 2.6rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 4px;
  padding: 0.16rem 0 0.16rem 0;
  margin-right: 0.2rem;
}

.leftBox ul {
  list-style: none;
}

.leftBox li {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #040404;
  opacity: 1;
  padding-left: 0.34rem;
  cursor: pointer;
}

.active {
  border-left: 3px solid #f46600;
  padding-left: 0.3rem !important;
}

.leftBox li:not(:first-child) {
  margin-top: 0.32rem;
}

.rightBox {
  margin-left: 2.6rem;
  width: 11rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 4px;
}
</style>