<!--
 * @FilePath: \zsw_new_edition\src\views\other\components\Protocol.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-25 14:21:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-02-07 14:42:15
-->
<!--协议组件-->
<template>
  <div>
    <div class="top" v-if="data">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户使用协议" name="1">
          <div class="content" v-if="data[0]">
            <div v-html="data[0].content"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="隐私政策" name="2">
          <div class="content" v-if="data[1]">
            <div v-html="data[1].content"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="会员服务协议" name="3">
          <div class="content" v-if="data[2]">
            <div v-html="data[2].content"></div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getOther } from "../../../Api";
export default {
  name: "About",
  props: ["type"],
  data() {
    return {
      data: {},
      activeName: "1",
    };
  },
  created() {
    if(this.$route.query.activeName){
      this.activeName = this.$route.query.activeName
    }
    getOther(1143).then((res) => {
      this.data = res.data;
    });
  },
  methods: {
    handleClick() {
      //将参数设置到地址栏
      let query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.activeName = this.activeName; //改变参数
      this.$router.push({ path: this.$route.path, query:query });
    },
  },
};
</script>

<style scoped>
.top >>> .el-tabs__active-bar {
  background-color: #f46600;
  bottom: 1px;
}
.top >>> .el-tabs__item.is-active {
  color: #f46600;
}
.top >>> .el-tabs__item:hover {
  color: #f46600;
  cursor: pointer;
}
::v-deep .el-tabs__item {
  padding: 0 20px;
  height: 0.5rem;
  box-sizing: border-box;
  line-height: 0.4rem;
  display: inline-block;
  list-style: none;
  font-size: 0.18rem;
  font-weight: bold;
  color: #040404;
  position: relative;
  font-family: PingFang SC;
}
.top {
  align-items: center;
  padding: 0.2rem 0 0 0.3rem;
}

.content {
  padding: 0 0.3rem 0.3rem 0;
}

.border {
  border-top: 1px dashed #d9d9d9;
  margin: 0 0.3rem;
}

.title {
  /* height: 0.22rem; */
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  opacity: 1;
  margin-bottom: 0.2rem;
}
</style>