<!--
 * @FilePath: \zhishe-pc\src\views\other\components\Feedback.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-12-07 16:07:28
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-17 10:51:46
-->
<!--意见反馈组件-->
<template>
  <div>
    <div class="top">
      <p>意见反馈</p>
    </div>
    <div class="content">
      <div class="hint">
        <p>尊敬的用户：</p>
        <p>
          您好！为了给您提供更好的服务，我们希望收集您使用知设网时的看法或建议。对您的配合和支持表示衷心感谢！
        </p>
        <p>
          如果提交有关浏览器速度、系统BUG、视觉显示问题，请注明您使用的操作系统、浏览器、以便我们尽快对应查找问题并解决。
        </p>
      </div>
      <div class="form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          label-position="left"
          label-width="100px"
        >
          <el-form-item label="反馈类型" prop="type">
            <el-radio-group v-model="ruleForm.type">
              <el-radio label="1">作品上传</el-radio>
              <el-radio label="2">账号问题</el-radio>
              <el-radio label="3">视频观看</el-radio>
              <el-radio label="4">素材下载</el-radio>
              <el-radio label="5">产品BUG</el-radio>
              <el-radio label="6">其他建议</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="上传图片" prop="img" class="imgBox">
            <el-upload
              class="avatar-uploader"
              :action="$ROOT_DIR + '/user/feedback/uploadMultiplePics'"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="ruleForm.img" :src="ruleForm.img" class="avatar" />
              <div v-else class="uploadBox">
                <div>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <p>上传相关图片</p>
                </div>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="输入内容" prop="content">
            <el-input
              type="textarea"
              v-model="ruleForm.content"
              placeholder="请填写反馈内容"
              :rows="5"
            ></el-input>
          </el-form-item>
          <el-form-item label="满意度调查" prop="satisfy">
            <div class="opinion">
              您对知设网的整体满意度如何呢？我们很想知道在您心目中的位置是怎样的
            </div>
            <el-radio-group v-model="ruleForm.satisfy" class="opinionRadio">
              <el-radio label="5">非常满意</el-radio>
              <el-radio label="4">满意</el-radio>
              <el-radio label="3">一般</el-radio>
              <el-radio label="2">不满意</el-radio>
              <el-radio label="1">非常不满意</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <label slot="label">姓&emsp;名</label>
            <el-input
              v-model="ruleForm.name"
              placeholder="请填写您的姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="contact">
            <el-input
              v-model="ruleForm.contact"
              placeholder="请填写您邮箱或手机号"
            ></el-input>
          </el-form-item>
          <el-form-item class="btn" v-if="btn">
            <el-button type="info" @click="submitForm('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { addFeedback } from "../../../Api";
import {mapGetters} from "vuex";
export default {
  name: "Feedback",
  data() {
    var validateContact = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系方式"));
      }
      let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      let reg = /^\w+@[a-zA-Z0-9]+((\.[a-z0-9A-Z]{1,})+)$/;
      if (!myreg.test(value) && !reg.test(value)) {
        callback(new Error("请输入正确的手机号或邮箱地址"));
      }
      callback();
    };
    return {
      btn:true,
      imageUrl: "",
      ruleForm: {
        type: "",
        img: "",
        content: "",
        satisfy: "",
        name: "",
        contact: "",
      },
      rules: {
        type: [
          { required: true, message: "请选择反馈类型", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入反馈内容", trigger: "blur" },
        ],
        satisfy: [
          { required: true, message: "请选择满意度", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        contact: [
          { required: true, validator: validateContact, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  mounted(){
    if (!this.isLogin) {
      this.$login();
    }
  },
  methods: {
    submitForm(formName) {
      if (!this.isLogin) {
        this.$login();
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addFeedback(this.ruleForm).then((res) => {
            if (res.data.error == "0") {
              this.$message.success("感谢您的反馈");
              this.btn = false;
            }
          });
        }
      });
    },
    //上传成功
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.ruleForm.img = res.data.filePath;
    },
  },
};
</script>

<style scoped>
.top {
  align-items: center;
  padding: 0.2rem 0 0.2rem 0.3rem;
  border-bottom: 1px solid #d9d9d9;
}
.top p {
  height: 0.25rem;
  font-size: 0.18rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #040404;
  opacity: 1;
}

.content {
  padding: 0.3rem 0.3rem 0.41rem;
}

.hint p {
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0.3rem;
  color: #333333;
  opacity: 1;
}

.form {
  margin-top: 0.4rem;
}

.avatar-uploader-icon {
  font-size: 0.28rem;
  color: #8c939d;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.uploadBox {
  width: 2.1rem;
  height: 1.6rem;
  background: #f7f6fb;
  opacity: 1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadBox p {
  font-size: 0.12rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #bfbfbf;
  opacity: 1;
}

.imgBox {
  margin-bottom: 0.4rem;
}

.el-textarea {
  width: 6.94rem;
}

.el-input {
  width: 2.44rem;
}

.opinion {
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  opacity: 1;
}

.btn button {
  margin-left: 5.76rem;
  width: 1.56rem;
}
</style>