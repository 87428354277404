<!--
 * @FilePath: \zhishe-pc\src\views\other\components\Platform.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-25 14:21:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-17 10:51:50
-->
<!--平台集锦组件-->
<template>
  <div>
    <div class="top">
      <p>平台集锦</p>
    </div>
    <div class="content">
      <div v-for="(item, index) in data" :key="index">
        <div class="borderBox" v-if="index != 1154"></div>
        <div class="title">
          <div class="icon"></div>
          <p class="text">{{ index | platformName }}</p>
        </div>
        <div class="box">
          <div class="qrcodeBox" v-for="(iitem, index) in item" :key="index">
            <div>
              <div class="qrcode">
                <img :src="iitem.src" alt="" />
              </div>
              <p>{{ iitem.cate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner } from "../../../Api";
export default {
  name: "Platform",
  data() {
    return {
      data: {},
    };
  },
  created() {
    getBanner({ position: "1154,1161,1165" }).then((res) => {
      this.data = res.data;
    });
  },
};
</script>

<style scoped>
.top {
  align-items: center;
  padding: 0.2rem 0 0.2rem 0.3rem;
  border-bottom: 1px solid #d9d9d9;
}
.top p {
  height: 0.25rem;
  font-size: 0.18rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #040404;
  opacity: 1;
}
.content {
  padding: 0.3rem;
}

.title {
  display: flex;
  align-items: center;
}
.icon {
  background-color: #ff9e58;
  width: 0.14rem;
  height: 0.14rem;
  margin-right: 0.1rem;
}

.text {
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #040404;
  opacity: 1;
}

.box {
  padding: 0.3rem 0.24rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.qrcodeBox:nth-child(1n) {
  margin-right: 2.5rem;
}

.qrcodeBox:nth-child(3n) {
  margin-right: 0 !important;
}

.qrcodeBox:nth-child(1n + 4) {
  margin-top: 1rem;
}

.box:after{
  content:"";
  width: 1.4rem;
}

.qrcode {
  width: 1.4rem;
  height: 1.4rem;
  opacity: 1;
  border-radius: 4px;
  background-color: black;
  margin-bottom: 0.1rem;
}

.qrcode img {
  width: 1.4rem;
  height: 1.4rem;
}

.qrcodeBox p {
  width: 1.4rem;
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #040404;
  opacity: 1;
  text-align: center;
}
.borderBox {
  border-top: 1px dashed #d9d9d9;
  margin: 0.3rem 0;
}
</style>